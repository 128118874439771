import React, { useEffect } from "react"
import { Form, Row, Typography, Input, Button } from "antd"
import "../style.scss"
import queryString from "query-string"
import { navigate } from "gatsby"

export default function SearchTableListCompany({ location, loading }) {
  const [form] = Form.useForm()

  const onFinish = (values) => {
    const query = queryString.parse(location.search)
    console.log(values)
    const newQueryStr = {
      ...query,
      ...values,
      ...{
        pageSize: 10,
        pageCurrent: 1,
      },
    }

    navigate(
      `/super-listed-image-management/?${queryString.stringify(newQueryStr)}`,
      undefined,
      {
        shallow: false,
      }
    )
  }
  useEffect(() => {
    const query = queryString.parse(location.search)
    form.setFieldsValue({
      keyword: query?.keyword,
      user_type: query?.user_type,
    })
  }, [location])
  return (
    <div className="customForm">
      <Form
        colon={false}
        onFinish={onFinish}
        form={form}
        style={{ backgroundColor: "#FAFAFA", padding: "1rem" }}
        labelCol={{ span: 4 }}
        requiredMark={false}
        wrapperCol={{ span: 17 }}
      >
        <Form.Item
          name="keyword"
          label={
            <Typography className="font-semibold" style={{ display: "flex" }}>
              キーワード
            </Typography>
          }
        >
          <Input
            placeholder="キーワードで探す"
            allowClear
            style={{ width: "70%" }}
          />
        </Form.Item>
        <Row className="justify-center pt-8">
          <Button
            htmlType="submit"
            className="w-36 ml-[10px]"
            type="primary"
            loading={loading}
          >
            検索
          </Button>
        </Row>
      </Form>
    </div>
  )
}
