import React, { useEffect, useState } from "react"
import { Pagination, Row, Spin } from "antd"
import { getListCompany } from "../../../services/superAdManageCompany"

import "../style.scss"

import SearchTableListCompany from "./SearchTableListCompany"
import { navigate } from "gatsby"
import queryString from "query-string"

import { itemRender } from "../../../customUtil/table"
import ModalImage from "./ModalImage"

function ListCompany({ location }) {
  const [isLoading, setIsLoading] = useState(false)
  const [listCompany, setListCompany] = useState(null)
  const [total, setTotal] = useState(0)

  const [tableParams, setTableParams] = useState(null)
  const handleGetListCompany = async () => {
    setIsLoading(true)
    const query = queryString.parse(location.search)
    const dataSent = {
      offset:
        (tableParams.pagination.current - 1) * tableParams.pagination?.pageSize,
      limit: tableParams.pagination.pageSize,
      q: query?.keyword ? query?.keyword : null,

      user_type:
        query?.user_type?.length > 0 ? JSON.stringify(query?.user_type) : null,
    }
    if (query?.order && query?.sorterField) {
      switch (query?.order) {
        case "ascend":
          dataSent.orderASC = [query?.sorterField]
          break
        case "descend":
          dataSent.orderDESC = [query?.sorterField]
          break
      }
    }
    const { response } = await getListCompany(dataSent)
    if (response.status == 200) {
      const query = queryString.parse(location.search)
      if (
        response.data.count &&
        Math.ceil(response.data.count / 10) < Number(query?.pageCurrent)
      ) {
        const newQueryStr = queryString.stringify({
          ...query,
          pageCurrent: 1,
        })
        navigate(
          `/super-listed-image-company/?${queryString.stringify(newQueryStr)}`,
          undefined,
          {
            shallow: false,
          }
        )
        return
      }
      setListCompany(response.data.stores)
      setTotal(response.data.count)

      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (tableParams) {
      handleGetListCompany()
    }
  }, [tableParams])

  const onPagenationChange = (page) => {
    const query = queryString.parse(location.search)
    const newQueryStr = {
      ...query,
      pageCurrent: page,
    }
    delete newQueryStr.sorterField
    delete newQueryStr.order

    navigate(
      `/super-listed-image-management/?${queryString.stringify(newQueryStr)}`,
      undefined,
      {
        shallow: false,
      }
    )
  }

  useEffect(() => {
    const query = queryString.parse(location.search)
    if (
      Number(query?.pageSize) &&
      Number(query?.pageCurrent) &&
      query?.pageSize == "10"
    ) {
      setTableParams({
        pagination: {
          current: Number(query?.pageCurrent),
          pageSize: 10,
        },
      })
    } else {
      const newQueryStr = queryString.stringify({
        pageSize: 10,
        pageCurrent: 1,
      })
      navigate(`/super-listed-image-management/?${newQueryStr}`)
    }
  }, [location])
  return (
    <div
      style={{
        width: "100%",
        // padding: "16px 20px",
        background: "#FFFFFF",
        borderRadius: "6px",
      }}
    >
      <Spin spinning={isLoading}>
        <div>
          <SearchTableListCompany loading={isLoading} location={location} />
        </div>

        <div className=" border-t-[rgba(0,0,0,0.06)] border-b-none p-5">
          {listCompany?.map((item, index) => {
            return <ModalImage store={item} key={index} storeId={item.id} />
          })}
          {listCompany?.length == 0 && (
            <div className="text-center text-[#111111]">
              {"検索結果は０です。"}
            </div>
          )}
        </div>
        <Row className="mb-5 px-5 flex justify-end">
          <Spin spinning={isLoading}>
            <Pagination
              current={tableParams?.pagination?.current}
              total={total}
              itemRender={itemRender}
              showSizeChanger={false}
              showTotal={(total, range) => (
                <div>
                  {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
                  {total}件)
                </div>
              )}
              onChange={onPagenationChange}
            />
          </Spin>
        </Row>
      </Spin>
    </div>
  )
}

export default ListCompany
